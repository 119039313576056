import type { LucideIcon } from "lucide-react";

const appearances = {
  cta: [
    "border border-white border-opacity-30 bg-gradient bg-clip-padding",
    {
      // TODO: add disabled state
      disabled: "",
      idle: "animate-bg",
      loading: "opacity-40 text-transparent cursor-not-allowed",
    },
  ],
  error: [
    "border-2 border-red-600 bg-transparent",
    {
      disabled: "opacity-40 text-white cursor-not-allowed",
      idle: "text-white hover:border-transparent hover:bg-red-600",
      loading: "opacity-40 text-transparent cursor-not-allowed",
    },
  ],
  primary: [
    "border-2 bg-sky-400 border-transparent",
    {
      disabled: "opacity-40 text-white cursor-not-allowed",
      idle: "text-white hover:border-sky-400 hover:bg-gray-900",
      loading: "opacity-40 text-transparent cursor-not-allowed",
    },
  ],
  secondary: [
    "border-2 border-sky-400 bg-transparent",
    {
      disabled: "opacity-40 text-white cursor-not-allowed",
      idle: "text-white hover:border-transparent hover:bg-sky-400",
      loading: "opacity-40 text-transparent cursor-not-allowed",
    },
  ],
  white: [
    "border-2 border-white bg-transparent",
    {
      // TODO: add loading and disabled states
      disabled: "",
      idle: "hover:bg-white hover:text-gray-900",
      loading: "",
    },
  ],
} as const;

const buttonSizes = {
  lg: [
    "px-5 py-2.5",
    {
      leftIcon: "mr-3 h-6 w-6",
      rightIcon: "ml-3 -mr-1 h-6 w-6",
    },
  ],
  md: [
    "px-4 py-1.5",
    {
      leftIcon: "mr-3 h-5 w-5",
      rightIcon: "ml-3 -mr-1 h-5 w-5",
    },
  ],
  xs: [
    "px-1 py-0.5 text-sm",
    {
      leftIcon: "mr-2 h-3 w-3",
      rightIcon: "ml-2 -mr-1 h3 w-3",
    },
  ],
} as const;

const iconButtonSizes = {
  lg: "p-3",
  md: "p-2",
  sm: "p-2",
};

type ButtonBaseProps = {
  children?: string;
  block?: boolean;
  rounded?: boolean;
  rightIcon?: LucideIcon;
  leftIcon?: LucideIcon;
  size?: keyof typeof buttonSizes;
  appearance?: keyof typeof appearances;
};

export { appearances, buttonSizes, iconButtonSizes };
export type { ButtonBaseProps };
